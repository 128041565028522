import http from "../axios-instance";

class DfToPcodeService {
   createDFtoPCode(model) {
      return http
         .post("/dpcode", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateDFtoPCode(model) {
      return http
         .put(`/dpcode/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getDFtoPCodesAdmin(params) {
      return http
         .get("/dpcode_admin", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getDFtoPCodeSingle(params) {
      return http
         .get("/dpcode_single", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteDFtoPCode(model) {
      return http
         .delete(`/dpcode/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new DfToPcodeService();
