import http from "../axios-instance";

class PCodeService {
   createPCode(model) {
      return http
         .post("/pcode", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updatePCode(model) {
      return http
         .put(`/pcode/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getPCodesAdmin(params) {
      return http
         .get("/pcode_admin", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getPCodeSingle(params) {
      return http
         .get("/pcode_single", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deletePCode(model) {
      return http
         .delete(`/pcode/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new PCodeService();
