import http from "../axios-instance";

export class OptionsService {
  
  static async createOption(option) {
    await http
      .post("/option", option)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static async updateOption(option) {
    await http
      .put(`/option/${option._id}`, option)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static async updateOptionPos(data) {
    await http
      .put(`/option/${data._id}/update_pos`, data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  
  static getOptions(params) {
    return http
      .get("/option", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static getAllOptions() {
    return http
      .get("/option_all")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static deleteOption(option) {
    return http
      .delete(`/option/${option._id}`, option)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
