import http from "../axios-instance";

class OrderMessageService {
  sendMessage(id, message) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    return http
      .post(`/order_message/${id}`, message, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getMessages(id, params) {
    return http
      .get(`/order_messages/${id}`, { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  deleteMessage(id) {
    return http
      .delete(`/order_message/${id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  downloadMsgFile(order, msg) {
    return http
      .get(`/order_message/${order._id}/${msg._id}/download_file`, { responseType: "blob" })
      .then((res) => {
        this.download(res.data, msg.file);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  download(data, fileName) {
    let blob = new Blob([data], {
      type: "application/octet-stream"
    });
    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    downloadElement.download = fileName.split(/__fn__(.+)/)[1];
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }
}

export default new OrderMessageService();
