import http from "../axios-instance";

class AgentOrderService {
   async createOrder(order, onUploadProgress) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
      };

      return http
         .post("/agent_order", order, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getOrders() {
      return http
         .get("/agent_order")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getOrder(order_id) {
      return http
         .get(`/agent_order/${order_id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getPendingOrders(params) {
      return http
         .get("/agent_pending_orders", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getCompleteOrders(params) {
      return http
         .get("/agent_complete_orders", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteOrder(order) {
      return http
         .delete(`/agent_order/${order._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   submitOrder(id, order, onUploadProgress) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
      };
      return http
         .put(`/agent_order/${id}/submit_order`, order, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   cancelOrder(id, order) {
      return http
         .put(`/agent_order/${id}/cancel_order`, order)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   changeOrderStatus(id, data) {
      return http
         .put(`/agent_order/${id}/change_status`, data)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   downloadOldFile(order) {
      return http
         .get(`/agent_order/${order._id}/download_old_file`, { responseType: "blob" })
         .then((res) => {
            this.download(res.data, order.original_file);
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   downloadNewFile(order) {
      return http
         .get(`/agent_order/${order._id}/download_new_file`, { responseType: "blob" })
         .then((res) => {
            this.download(res.data, order.modified_file);
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getPendingOrdersCount() {
      return http
         .get("/agent_pending_orders_count")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getCompleteOrdersCount() {
      return http
         .get("/agent_complete_orders_count")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getWarningOrdersCount() {
      return http
         .get("/agent_warning_orders_count")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   download(data, fileName) {
      let blob = new Blob([data], {
         type: "application/octet-stream",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName.split(/__fn__(.+)/)[1];
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
   }
}

export default new AgentOrderService();
