import http from "../axios-instance";

class GenerationService {
  async create(model) {
    return http
      .post("/vehicle/generation", model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  async update(model) {
    return http
      .put(`/vehicle/generation/${model._id}`, model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  async getAll(params) {
    return http
      .get("/vehicle/generation", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  async delete(model) {
    return http
      .delete(`/vehicle/generation/${model._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  async getSelected(vmodelID) {
    return http
      .get(`/vehicle/generation/selected/${vmodelID ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new GenerationService();
