import http from "../axios-instance";

class FileUploadService {
   async uploadFile(order, onUploadProgress) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
      };

      return http
         .post("/file_upload", order, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new FileUploadService();
